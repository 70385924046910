import Axios from "axios";
import { REACT_APP_API_PROVIDERS, REACT_APP_API_SIM } from '../../config'


// curl -X POST "https://test.sim.tygo.net/api/v2/Browse/1/CheckEligibility" -H  "accept: text/plain; ver=2.0" -H  "Content-Type: application/json; ver=2.0" -d "{\"name\":\"string\",\"nationality\":0,\"idNumber\":\"string\",\"idType\":0,\"contractType\":\"string\",\"customerContactNumber\":\"string\",\"lng\":\"string\",\"lat\":\"string\",\"plan_id\":\"string\",\"countryInfo\":{\"name\":\"string\",\"tcC_ID\":\"string\",\"countryCode\":\"string\",\"alpha2Code\":\"string\",\"alpha3Code\":\"string\",\"isO3166Code\":\"string\"}}"

const checkEligibility = async (data: any, providerId: number) => {
  const user = JSON.parse(localStorage.getItem('tygo_user') || '{}');

  const response = await Axios.post(
    REACT_APP_API_SIM + "/api/v2/Browse/" + providerId + "/CheckEligibility",
    data,
    {
      headers: {
        accept: "text/plain; ver=2.0",
        "Content-Type": "application/json; ver=2.0",
        authorization: "Bearer " + user.token,
      },
    }
  );
  return response.data;
}

export {
  checkEligibility,
  checkout,
  getNumbers,
  getResellerPlanId,
  otpConfirm,
  generateCheckoutId
}

// curl -X POST "https://test.sim.tygo.net/api/v2/Order/14/GenerateCheckoutId" -H  "accept: text/plain; ver=2.0" -H  "Authorization: Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjNhNTE0NjM1LWIzMGYtNGJjNi04YTE0LTI5ZDI5ZjU3OTBjMyIsImFwcC1pZCI6IlR5Z29XZWIiLCJjbGllbnQtaWQiOiJUeWdvQXBwIiwiU2VjdXJpdHlTdGFtcCI6IktWQU1aQ1BWT1VDNUU3TjVCNjNPUU1MNloyUFI3UDdOIiwiZmlyc3ROYW1lIjoiTm91cmVkZGRpbmUiLCJsYXN0TmFtZSI6IiIsIm5hbWUiOiJOb3VyZWRkZGluZSIsInBob25lTnVtYmVyIjoiIiwibmJmIjoiMTc0MjUwMjY0NiIsImV4cCI6IjE3NDUwOTQ2NDYiLCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo1MDAxIiwiYXVkIjoiVGF5Z29BcGkiLCJEZXZpY2VUeXBlIjoiV2ViIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQWRtaW4ifQ.Asww7UrmqsHdmZhuE-9S0Lk_9GXJ1GZxXAkt1DnIbyk" -H  "Content-Type: application/json; ver=2.0" -d "{\"email\":\"string\",\"street\":\"string\",\"city\":\"string\",\"state\":\"string\",\"country\":\"string\",\"postcode\":\"string\",\"givenName\":\"string\",\"surname\":\"string\",\"phoneNumber\":\"string\",\"countryCode\":\"string\",\"amount\":0,\"entityType\":\"VISA\",\"userId\":\"string\",\"paymentFor\":\"string\",\"productIds\":\"string\",\"productNames\":\"string\",\"tygoOrderId\":\"string\",\"promoCode\":\"string\",\"deviceType\":\"string\",\"deviceVersion\":\"string\"}"

const generateCheckoutId = async (data: any, providerId: number) => {
  const user = JSON.parse(localStorage.getItem('tygo_user') || '{}');
  if (user) {
    const response = await Axios.post(
      REACT_APP_API_SIM + "/api/v2/Order/" + providerId + "/GenerateCheckoutId",
      data,

      {
        headers: {
          accept: "text/plain; ver=2.0",
          authorization: "Bearer " + user.token,
        },
      }
    );
    return response.data;
  }
  else {
    return null;
  }
}


// // curl -X POST "https://test.sim.tygo.net/api/v2/Order/14/Checkout" -H  "accept: text/plain; ver=2.0" -H  "Content-Type: application/json; ver=2.0" -d "{\"nationality\":{\"id\":\"string\",\"idType\":\"string\",\"eligible\":true,\"country_id\":\"string\",\"country_name\":\"string\"},\"number\":{\"reservation_id\":\"string\",\"identifier\":\"string\"},\"customer\":{\"email\":\"string\",\"email_verified\":\"string\",\"contact_number_verified\":\"string\",\"contact_number\":\"string\",\"contact_number_encrypted\":\"string\",\"name\":\"string\",\"visitorOTPType\":\"string\",\"countryCode\":\"string\"},\"delivery\":{\"delivery_lat\":\"string\",\"delivery_lng\":\"string\",\"delivery_notes\":\"string\",\"delivery_country\":\"string\",\"delivery_address\":\"string\",\"delivery_city\":\"string\",\"delivery_district\":\"string\",\"delivery_postalcode\":\"string\"},\"plan_id\":\"string\",\"sim_type\":0,\"numberOrderType\":0,\"mnpOperator\":\"string\",\"mnpNumber\":\"string\"}"
const checkout = async (data: any, providerId: number) => {
  const user = JSON.parse(localStorage.getItem('tygo_user') || '{}');
  if (user) {
  const response = await Axios.post(
    REACT_APP_API_SIM + "/api/v2/Order/" + providerId + "/Checkout",
    data,
    {
      headers: {
        accept: "text/plain; ver=2.0",
        "Content-Type": "application/json; ver=2.0",
        authorization: "Bearer " + user.token,
      },
    }
  );
  return response.data;
  }
  else {
    return null;
  }
}
// curl -X GET "https://test.sim.tygo.net/api/v2/Browse/1/GetNumbers?vanityId=3&planeId=2" -H  "accept: text/plain; ver=2.0"
const getNumbers = async (vanityId: number | null, planeId: number, providerId: number) => {
  const user = JSON.parse(localStorage.getItem('tygo_user') || '{}');
  if (user) {
  const response = await Axios.get(
    REACT_APP_API_SIM + "/api/v2/Browse/" + providerId + "/GetNumbers?" + (vanityId !== null ? "vanityId=" + vanityId + "&" : "") + "planeId=" + planeId,
    {
      headers: {
        accept: "text/plain; ver=2.0",
        authorization: "Bearer " + user.token,
      },
    } 
    );
    return response.data;
  }
  else {
    return null;
  }
}
// https://test.providers.tygo.net/api/plan/437/ResellerPlanId

const getResellerPlanId = async (planId: number) => {
  const response = await Axios.get(
    REACT_APP_API_PROVIDERS + "/api/plan/" + planId + "/ResellerPlanId",
  );
  return response.data;
}



//curl -X POST "https://test.sim.tygo.net/api/v2/Order/14/OtpConfirm" -H  "accept: text/plain; ver=2.0" -H  "Authorization: Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjNhNTE0NjM1LWIzMGYtNGJjNi04YTE0LTI5ZDI5ZjU3OTBjMyIsImFwcC1pZCI6IlR5Z29XZWIiLCJjbGllbnQtaWQiOiJUeWdvQXBwIiwiU2VjdXJpdHlTdGFtcCI6IktWQU1aQ1BWT1VDNUU3TjVCNjNPUU1MNloyUFI3UDdOIiwiZmlyc3ROYW1lIjoiTm91cmVkZGRpbmUiLCJsYXN0TmFtZSI6IiIsIm5hbWUiOiJOb3VyZWRkZGluZSIsInBob25lTnVtYmVyIjoiIiwibmJmIjoiMTc0MjUwMjY0NiIsImV4cCI6IjE3NDUwOTQ2NDYiLCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo1MDAxIiwiYXVkIjoiVGF5Z29BcGkiLCJEZXZpY2VUeXBlIjoiV2ViIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQWRtaW4ifQ.Asww7UrmqsHdmZhuE-9S0Lk_9GXJ1GZxXAkt1DnIbyk" -H  "Content-Type: application/json; ver=2.0" -d "{\"id\":\"string\",\"code\":\"string\"}"
const otpConfirm = async (data: { id: string; code: string }, providerId: number) => {
  const user = JSON.parse(localStorage.getItem('tygo_user') || '{}');
  
  if (user) {
    const response = await Axios.post(
      REACT_APP_API_SIM + "/api/v2/Order/" + providerId + "/OtpConfirm",
      data,
      {
        headers: {
          accept: "text/plain; ver=2.0",
          "Content-Type": "application/json; ver=2.0",
          authorization: "Bearer " + user.token,
        },
      }
    );
    return response.data;
  } else {
    return null;
  }
}