
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CompareCardContext } from "../../../components/compare/CompareCard";
import Button, { ButtonSquare } from "../../../components/design/buttons/Button";
import Card from "../../../components/design/cards/card";
import Footer from "../../../components/footer/footer";
import { getPlanbyId } from "../../../services/stepper.service";
import ProgressBar from "../../stepper/components/ProgressBar";
import PlanBar from "../components/planBar";
import PlanHeader from "../components/planHeader";
import './plan.css';
import Header from "../../../components/header/header";

import { FiGlobe } from 'react-icons/fi';
// FaRegEnvelope
import { FaRegEnvelope } from 'react-icons/fa';
// IoCallOutline
import { HiOutlinePhone } from 'react-icons/hi';
// HiOutlineUserGroup
import { HiOutlineUserGroup } from 'react-icons/hi';
// FiClock
import { FiClock } from 'react-icons/fi';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { toast } from "react-toastify";
import { TbDeviceSim } from "react-icons/tb";
import DownloadSection from "../../landing/components/download";
import { getProvider } from "../../../services/providers.service";
import { BiQrScan } from "react-icons/bi";

const PlanScreen = () => {
    const navigate = useNavigate();
    const id = useParams().id;
    const [lastdata, setLastData] = React.useState<any>([]);
    const [dataHeader, setDataHeader] = React.useState<any>(null);
    const {addToCart} = useContext(CompareCardContext);
    const {t, i18n} = useTranslation();
    const [show, setShow] = React.useState(false);
    const [showSim, setShowSim] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleCloseSim = () => setShowSim(false);
    const handleShowSim = () => setShowSim(true);
    const handleShow = () => setShow(true);
    const [provider, SetProvider] = React.useState<any>();
    const [ELEMENTS, setELEMENTS] = React.useState<any>({
        messagesTitle: [
            {
                title: t("details.messages"),
                value: "0",
                icon: <FaRegEnvelope className="icon_det" style={{color: '#3EB6AE', fontSize:"0.5rem"}} />
            },
            {
                title: t("details.messages_other"),
                value: "0",
                icon: <FaRegEnvelope className="icon_det" style={{color: '#3EB6AE', fontSize:"0.5rem"}} />
            },
            {
                title: t("details.messages_international"),
                value: "0",
                icon: <FaRegEnvelope className="icon_det" style={{color: '#3EB6AE', fontSize:"0.5rem"}} />
            }
        ],
        internetTitle: [
            {
                title: t("details.local_internet"),
                value: "0",
                icon: <FiGlobe className="icon_det" style={{color: '#3EB6AE', fontSize:"0.5rem"}} />
            },
            {
                title: t("details.social_internet"),
                value: "0",
                icon: <HiOutlineUserGroup className="icon_det" style={{color: '#3EB6AE', fontSize:"0.5rem"}} />
            }
        ],
        callsTitle: [
            {
                title: t("details.calls"),
                value: "0",
                icon: <HiOutlinePhone className="icon_det" style={{color: '#3EB6AE', fontSize:"0.5rem"}} />
            },
            {
                title: t("details.calls_other"),
                value: "0",
                icon: <HiOutlinePhone className="icon_det" style={{color: '#3EB6AE', fontSize:"0.5rem"}} />
            },
            {
                title: t("details.calls_international"),
                value: "0",
                icon: <HiOutlinePhone className="icon_det" style={{color: '#3EB6AE', fontSize:"0.5rem"}} />
            }
        
        ],
        moreTitle: [
            {
                title: t("details.validity"),
                value: "0",
                icon: <FiClock className="icon_det" style={{color: '#3EB6AE', fontSize:"0.5rem"}} />
            },
            {
                title: t("details.provider"),
                value: "none",
                icon: <TbDeviceSim className="icon_det" style={{color: '#3EB6AE', fontSize:"0.5rem"}} />
            },
            {
                title: t("details.type"),
                value: "none",
                icon: <TbDeviceSim className="icon_det" style={{color: '#3EB6AE', fontSize:"0.5rem"}} />
            }
        ]
    });
        

    const feachData = () => {
        // https://test.providers.tygo.net/api/plan/42/customer

        getPlanbyId(id, i18n.language)
        .then(async (a: any) => {
            const data = a.data.returnData;
            console.log(a);
            const last = 
            [{
                title: t("details.calls"),
                value: data.minutesInSideProvider !== -1 ? data.minutesInSideProvider + " " +t("plans.minutes") : t("plans.unlimited"),
                // icon: 'HiOutlinePhone',
                icon: <HiOutlinePhone className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                }, {
                title: t("details.calls_other"),
                value: data.minutesOutSideProvider !== -1 ? data.minutesOutSideProvider + " " +t("plans.minutes") : t("plans.unlimited"),
                // icon: 'HiOutlinePhone',
                icon: <HiOutlinePhone className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                }, {
                title: t("details.calls_international"),
                value: data.internationalCalls !== -1 ? data.internationalCalls + " " +t("plans.minutes") : t("plans.unlimited"),
                // icon: 'HiOutlinePhone',
                icon: <HiOutlinePhone className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                }, {
                title: t("details.local_internet"),
                value: data.localInternt !== -1 ? data.localInternt/1000 + " " +t("plans.gb") : t("plans.unlimited"),
                // icon: 'FiGlobe',
                icon: <FiGlobe className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                }, {
                title: t("details.messages"),
                value: data.smSForCurrentProvider !== -1 ? data.smSForCurrentProvider + " " +t("plans.message") : t("plans.unlimited"),
                // icon: 'FaRegEnvelope',
                icon: <FaRegEnvelope className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                }, {
                title: t("details.social_internet"),
                value: data.socialMedia !== -1 ? data.socialMedia/1000 + " " +t("plans.gb") : t("plans.unlimited"),
                // icon: 'fas fa-users',
                icon: <HiOutlineUserGroup className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                }, {
                title: t("details.messages_other"),
                value: data.smSForOtherProvider !== -1 ? data.smSForOtherProvider + " " +t("plans.message") : t("plans.unlimited"),
                // icon: 'FaRegEnvelope',
                icon: <FaRegEnvelope className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                }, {
                title: t("details.messages_international"),
                value: data.internationalCalls !== -1 ? data.internationalCalls + " " +t("plans.message") : t("plans.unlimited"),
                // icon: 'FaRegEnvelope',
                icon: <FaRegEnvelope className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                },
                // {
                //     title: t("details.messages_international"),
                //     value: data.internationalCalls !== -1 ? data.internationalCalls + " " +t("plans.message") : t("plans.unlimited"),
                //     // icon: 'FaRegEnvelope',
                //     icon: <FaRegEnvelope className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                //     },
                {
                title: t("details.validity"),
                value: data.validityTimeInDays + " " +t("plans.days"),  
                // icon: 'fas fa-clock',
                icon: <FiClock className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                }
            ]
            // Request URL: https://test.providers.tygo.net/api/provider/customer
           
            const providerId = data.providerId;
            const provider = await getProvider(providerId, i18n.language);
            SetProvider(provider.filter((a: any) => a.id === providerId)[0]);

            console.log(provider);
            // name
            setDataHeader({
                title: data.name,
                image: data.imageUrl,
                price: data.price,
                newPrice: data.discountRate === 0 ? data.price : data.price - (data.price * data.discountRate),
                hasDiscount: data.discountRate === 0 ? false : true,
                providerName: provider.filter((a: any) => a.id === providerId)[0].name,
                typeName: t(data.contractTypeId === 1 ? t("details.prepaid") : t("details.postpaid")),
                id: data.id,
                likes: data.likes || 0,
                issueThroughTygo: data.issueThroughTygo,
            } as any);
            setELEMENTS({
                internetTitle: [
                    {
                        title: t("details.local_internet"),
                        value: data.localInternt !== -1 ? (data.localInternt/1000).toLocaleString() + " " +t("plans.gb") : t("plans.unlimited"),
                        icon: <FiGlobe className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                    },
                    {
                        title: t("details.social_internet"),
                        value: data.socialMedia !== -1 ?(data.socialMedia/1000).toLocaleString() + " " +t("plans.gb") : t("plans.unlimited"),
                        icon: <HiOutlineUserGroup className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />,
                        body: 
                           
                            data.features.split(":").length > 1 &&
                            <div style={{display: 'flex', flexWrap: 'wrap', gap: '0.5rem', borderTop: '1px solid #f1f1f1'}} className="pt-2 w-100">
                            {data.features.split(":")[1].trim().replace(" and ", ", ").split(",").map(app => app.trim()).
                            map((a: any) => a.toLowerCase()).map((a: any) => a.replace("\"", ""))
                            .map((item: any, index: number) => {
                                return <img src={"/media/svgs/media/" + item + ".svg"} key={index} style={{width: '1rem', height: '1rem'}} />
                            }
                            )}
                            </div>
                            

                        
                    }
                ],
                callsTitle: [
                    {
                        title: t("details.calls"),
                        value: data.minutesInSideProvider !== -1 ? (data.minutesInSideProvider).toLocaleString() + " " +t("plans.minutes") : t("plans.unlimited"),
                        icon: <HiOutlinePhone className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                    },
                    // {
                    //     title: t("details.calls_other"),
                    //     value: data.minutesInSideProvider !== -1 ? (data.minutesInSideProvider).toLocaleString() + " " +t("plans.minutes") : t("plans.unlimited"),
                    //     icon: <HiOutlinePhone className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                    // },
                    {
                        title: t("details.calls_international"),
                        value: data.internationalCalls !== -1 ? (data.internationalCalls).toLocaleString() + " " +t("plans.minutes") : t("plans.unlimited"),
                        icon: <HiOutlinePhone className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                    }
                ],
                messagesTitle: [
                    {
                        title: t("details.messages"),
                        value: data.smSForCurrentProvider !== -1 ? data.smSForCurrentProvider.toLocaleString() + " " +t("plans.message") : t("plans.unlimited"),
                        icon: <FaRegEnvelope className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                    },
                    // {
                    //     title: t("details.messages_other"),
                    //     value: data.smSForOtherProvider !== -1 ? data.smSForOtherProvider.toLocaleString() + " " +t("plans.message") : t("plans.unlimited"),
                    //     icon: <FaRegEnvelope className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                    // },
                    // {
                    //     title: t("details.messages_international"),
                    //     value: data.internationalCalls !== -1 ? data.internationalCalls.toLocaleString() + " " +t("plans.message") : t("plans.unlimited"),
                    //     icon: <FaRegEnvelope className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                    // }
                ],
               
                moreTitle: [
                    {
                        title: t("details.validity"),
                        value: (data.validityTimeInDays).toLocaleString() + " " +t("plans.days"),  
                        icon: <FiClock className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                    },
                    {
                        title: t("details.provider"),
                        value: provider.filter((a: any) => a.id === providerId)[0].name,
                        icon: <TbDeviceSim className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                    },
                    {
                        title: t("details.type"),
                        value: t(data.contractTypeId === 1 ? t("details.prepaid") : t("details.postpaid")),
                        icon: <TbDeviceSim className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                    }
                ]
            });



            setLastData(last);
        }
        )
    }

    const copyUrl = () => {
        navigator.clipboard.writeText(window.location.href);
        toast.success(t("details.copied"));
    }
    
    useEffect(() => {
        feachData();  
        
    }, [id, i18n.language])
    if(!dataHeader || !lastdata) return (<div>
        {/* Loading... */}
        </div>
    )
    return ( 
        <div className="recommended-plans">
            
            <div className="container my-2 px-3">
            <Header />
            </div>
            <div style={{
                width: '100%',
                height: '32px',
                backgroundColor: provider.primaryColor
            }}>

            </div>
            <div className="container my-4 px-3">

            <div className="d-flex mb-4 " >
            <div style={
                {
                    scale: "0.8",
                }
            }>
            <Button color="circle "   isfullWidth={false} onClick={()=>{
                navigate(-1);
            }} inner={
               <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: i18n.language === "ar" ? "rotate(180deg)" : ""}}>
               <path d="M15.95 31.3669C16.2666 31.3669 16.5833 31.2502 16.8333 31.0002C17.3166 30.5169 17.3166 29.7169 16.8333 29.2335L7.59997 20.0002L16.8333 10.7669C17.3166 10.2835 17.3166 9.48353 16.8333 9.0002C16.35 8.51686 15.55 8.51686 15.0666 9.0002L4.94996 19.1169C4.46663 19.6002 4.46663 20.4002 4.94996 20.8835L15.0666 31.0002C15.3166 31.2502 15.6333 31.3669 15.95 31.3669Z" fill="#292D32"/>
               <path d="M6.1165 21.25H34.1665C34.8498 21.25 35.4165 20.6833 35.4165 20C35.4165 19.3167 34.8498 18.75 34.1665 18.75H6.1165C5.43317 18.75 4.8665 19.3167 4.8665 20C4.8665 20.6833 5.43317 21.25 6.1165 21.25Z" fill="#292D32"/>
               </svg>
               
            } />
            </div>
            <strong className=" m-3">{t("global.back")}</strong>
            </div>
            <PlanHeader plan={
                dataHeader
            } share={copyUrl} />
            <div className="d-block d-lg-none">
            {
                    // .issueThroughTygo
                    dataHeader.issueThroughTygo &&
                
                <div className="col-12 col-lg-4 mt-lg-4 mb-lg-5 my-2">
                    <Button isfullWidth={true} color={"primary py-4"} inner={<div className="d-flex">
                            <p className="font-weight-light m-0">{t("details.download")}</p>
                        </div>} onClick={
                            handleShow
                        } />
                </div>
                }
               
                <div className="col-12 col-lg-4 mt-lg-4 mb-lg-5 my-2">
                    <Button isfullWidth={true} color={"py-4 primary-outline-primary "} inner={<div className="d-flex">
                            <p className="font-weight-light m-0">{t("details.compare")}</p>
                        </div>} onClick={
                            () => {
                                addToCart(dataHeader, '');
                                navigate(-1);
                            }
                        } />

                </div> 
                </div>
            <div className="row plan-details mt-lg-3 mt-2 row row-cols-2 row-cols-lg-3 justify-content-center"
            >
            {/* {
                    lastdata.map((item: any, index: number) => {
                        return <div className="col" key={index}>
                            <Card
                            noEdge={true}
                            className={"px-0 py-2"} inner={
                                <div className="p-lg-4 p-2 text-lg-start text-center">
                                    <div className="d-lg-flex d-block " style={{minHeight: '3rem'}}> 
                                        {item.icon}
                                        <div className="mx-2"> </div>
                                        <p className="font-weight-light text-muted m-lg-0 mt-4">{item.title}</p>
                                    </div>
                                    <strong className="d-block mt-lg-4 mt-3" style={{color: '#3EB6AE', fontSize: '1rem'}}>
                                        {item.value}
                                    </strong>
                                </div>
                            } />
                        </div>
                    })
                    
                } */}
                {
                    Object.keys(ELEMENTS).map((key_: any, index: number) => {
                        return <div className="col-12 col-lg-3 mt-lg-3 mb-lg-5 my-2" key={index}>
                            <Card
                            noEdge={true}
                            fullheight={true}
                            className={"px-0 py-0"} inner={
                                <div className="p-lg-4 p-2 text-lg-start text-center">
                                    <h3 className="title_carddetails mb-3 mt-0">{t("details." + key_)}</h3>
                                    <span className="text_cardValue">
                                        {/* {t("details." + key + "_sub")}÷÷ */}
                                        {/* {
                                            index < 3 ? 
                                            ELEMENTS[key].filter((a: any) => a.value === "Unlimited").length === 0 ?
                                            ELEMENTS[key].reduce((a: any, b: any) => {
                                                return (parseInt(a) + parseInt(b.value));
                                            }, 0).toLocaleString() + " " + (t("details." + key) === "Internet" ? t("plans.gb"):t("details." + key))
                                            :
                                                "Unlimited " + (t("details." + key) === "Internet" ? t("plans.gb"):t("details." + key))
                                            : 
                                            "    ."
                                        } */}
                                       

                                    </span>
                                    {
                                        ELEMENTS[key_].map((item: any, index: number) => {
                                            return <div className="">
                                                <div className="d-flex align-items-center mb-3" style={{minHeight: '3rem'}} key={index}> 
                                                <div className="icon_det_">
                                                {item.icon}
                                                </div>
                                                <div className="mx-2"></div>
                                                <div>
                                                <p className="det_element m-0">{item.title}</p>
                                                <strong className="det_value">
                                                    {item.value}
                                                </strong>
                                                
                                                </div>
                                            </div>
                                            {
                                                    //body
                                                    item.body ?
                                                    <div className="d-lg-flex d-block align-items-center mb-3">
                                                        <div className="icon_det_ " style={{opacity:"0"}}>
                                                {item.icon}
                                                </div>
                                                <div className="mx-2"></div>

                                                        {item.body}
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                            
                            } />
                        </div>
                    }
                    )
                }
                 {/* {
                    // .issueThroughTygo
                    dataHeader.issueThroughTygo &&
                
                <div className="col-12 col-lg-4 mt-lg-4 mb-lg-5 my-2 d-none d-lg-block">
                    <Button isfullWidth={true} color={"primary py-4"} inner={<div className="d-flex">
                            <p className="font-weight-light m-0">{t("details.download")}</p>
                        </div>} onClick={
                            handleShow
                        } />
                </div>
                } */}
               
                <div className="col-12 col-lg-4 mt-lg-4 mb-lg-5 my-2 d-none d-lg-block">
                    <Button isfullWidth={true} color={"py-4 primary-outline-primary "} inner={<div className="d-flex">
                            <p className="font-weight-light m-0">{t("details.compare")}</p>
                        </div>} onClick={
                            () => {
                                addToCart(dataHeader, '');
                                navigate(-1);
                            }
                        } />
                    
                </div>
                {dataHeader.issueThroughTygo &&
                <div className="col-12 col-lg-4 mt-lg-4 mb-lg-5 my-2 d-none d-lg-block">
                    <Button isfullWidth={true} color={"py-4 primary"} inner={<div className="d-flex">
                            <p className="font-weight-light m-0">{t("details.buy")}</p>
                        </div>} onClick={
                            dataHeader.simTypeId != 3?
                            handleShowSim:
                            ()=>navigate("/sim?id="+id)
                             } />
                    
                </div> 
                } 
                </div>
                <div className=" noEdge details-card-in  px-0 py-2 full-height w-100">
                    <div className="p-4">

                    <strong>{t("details.more.title")}</strong>
                    <div className="dividers my-3"></div>
                    {t("details.more.description")}
                    </div>

                </div>
                </div>
                <DownloadSection />
                <Footer />
                {/* <Footer /> */}
                <Modal show={show} onHide={handleClose}>
        <Modal.Body>
                    
                        <h1 className='mb-2 mt-2 mt-lg-3 mb-lg-5 text-center
                        '>{t("footer.title")}</h1>
                        <p className='my-2 my-lg-5 text-center'>
                            {t("footer.sub_title")}
                        </p>
                        <div className="d-flex justify-content-center">
          <div className='m-2'> 
          <a href="https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone](https://apps.apple.com/qa/app/tygo/id1619973559?platform=iphone)" target="_blank">
          <img src="/media/buttons/apple.svg" width={124} />
          </a>
          </div>
          <div className='m-2'>
          <a href='https://play.google.com/store/apps/details?id=app.tygo.sa' target="_blank">
          <img src="/media/buttons/google.svg" width={124} />
          </a>
          </div>
                </div>
        </Modal.Body>
      </Modal>
      <Modal show={showSim} onHide={handleCloseSim}>
        <Modal.Body>
        <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
            <h1>{t("local_sim.select_type.title")}</h1>
            <div className="d-flex  gap-3">
                <div className="w-100">
                    <ButtonSquare inner={
                        <div className="d-flex align-items-center  gap-2 justify-content-center" style={{flexDirection: "column"}}>
                            <BiQrScan className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                            <span>{t("local_sim.select_type.options.esim")}</span>
                        </div>
                    } color="primary-outline-primary p-5 m-0" isfullWidth={true} isrounded={true} onClick={()=>navigate("/sim?id="+id+"&type=esim")}></ButtonSquare>
                </div>
                <div className="w-100">
                    <ButtonSquare inner={<div className="d-flex align-items-center  gap-2 justify-content-center" style={{flexDirection: "column"}}>
                            <TbDeviceSim className="icon_det" style={{color: '#3EB6AE', fontSize: '1.5rem'}} />
                            <span>{t("local_sim.select_type.options.sim")}</span>
                        </div>} color="primary-outline-primary p-5 me-0" isfullWidth={true} isrounded={true} onClick={()=>navigate("/sim?id="+id+"&type=sim")}></ButtonSquare>
                </div>
            </div>
        </div>
        </Modal.Body>
      </Modal>
        </div>
    );
};

export default PlanScreen;
