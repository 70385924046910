// SignIn
// import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import Button from '../../components/design/buttons/Button';
// import Card from '../../components/design/cards/card';
// import Header from '../../components/header/header';
// import Footer from '../../components/footer/footer';
// import './Page.css'
// import React from 'react';
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createAccount, loginAccount, verifyCode } from "../../services/accounts.service";
import Button from "../../components/design/buttons/Button";
import Footer from "../../components/footer/footer";
import { Modal } from "react-bootstrap";
import Header from "../../components/header/header";
import React from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import "./Login.css";
const Wrapper = () => {
    
    const [isRegister, setIsRegister] = useState<boolean>(false);
    const [state, setState] = useState<string>("login");
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get('redirect');
    
    return (
        <div style={{backgroundImage: "url(./media/hero/bg2.png)", backgroundColor:"#F7F7F7",minHeight:"100vh"}} className="d-flex flex-column justify-content-between">
            <Header/>
            {/* {redirect} */}
            <div className="container h-100">
                {isRegister ? <SignIn/> :
                <Login to={redirect} />}
                <div className="w-100 text-center mt-3">
                    <span className="devider">
                        <span>{t("login.or")}</span>
                    </span>
                </div>
                    <div className="text-center w-100">
                        {t("login.you_have_no_account")} <a onClick={(e) => {
                            e.preventDefault();
                            setIsRegister(!isRegister);
                        } }
                        >{t("login.register")}</a>
                    </div>
                    <div className='d-flex justify-content-center mt-4 flex-column align-items-center'>
                        <img src="/media/svgs/cst.svg" width={52}/>        
                        <span style={{textAlign:"start", fontWeight:"300", padding:"16px"}} className=" ">{t("login.tygo_cst_text")}</span>
                    </div>
                    
                </div>
            <Footer />
        </div>
    );
};
const SignIn = () => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [error, setError] = useState<string>("");

    return (
        <div>
            <div className="page-container ">
                {/* <Header /> */}
                <div className="container h-100">
                <div className="card_form">
            <h1 className="my-4 d-none d-md-block">
                {t("store.register.title")}
            </h1>
            <h6 className=" d-block d-md-none">
                {t("store.register.title")}
            </h6>
            <div className="row payment_row my-3">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="">{t("store.register.name")}</label>
                        <input type="text" className="form-control" onChange={(e) => setName(e.target.value)} />
                    </div>
                </div>
                <div className="col-md-12">
                <div className="form-group">
                        <label htmlFor="">{t("store.register.email")}</label>
                        <input type="text" className="form-control" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>
                <div className="col-md-12">
                <div className="form-group">
                        <label htmlFor="">{t("store.register.phone")}</label>
                        <input type="text" className="form-control" onChange={(e) => setPhone(e.target.value)} />
                    </div>
                </div>
                </div>
                <p className="mb-2 pb-3 d-block">{t("store.register.info")}</p>
                            <p className="text-danger">{error}</p>
                            <Button inner={t("store.register.submit")}
                             color="primary mt-2" isfullWidth  isLoading={loading} />
               
                 
                </div>
                </div>
            </div>
        </div>
    );
};

const Login = ({to} : {to: string | null}) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [emailMobile, setEmailMobile] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [state, setState] = useState<string>("login");
    const [method, setMethod] = useState<string>("PhoneNumber");
    const login = async () => {
        setLoading(true);
        await loginAccount({
            email: emailMobile,
            phoneNumber: emailMobile,
            countryCode: "966",
            logintType: emailMobile.includes("@") ? "Email" : "PhoneNumber",
        }).then((res) => {
            if(res.status === 200) {
                setState("otp");
                //save user email
                const user = JSON.parse(localStorage.getItem("tygo_user") || '{}');
                user.email = emailMobile;
                localStorage.setItem("tygo_user", JSON.stringify(user));
                setMethod(emailMobile.includes("@") ? "Email" : "PhoneNumber");
            }
            setLoading(false);
        }).catch((err) => {
            // alert(err.response.data.Message);
            setError(err.response.data.Message);
            setLoading(false);
        });
    }

    return (
        <div>
            <div className="page-container " >
                {/* <Header /> */}
                <div className="container">
                <div className="card_form">
                    <h1 className="text-center my-4 d-none d-md-block">
                        {t("login.title")}
                    </h1>
                    <h6 className="text-center d-block d-md-none">
                        {t("login.title")}
                    </h6>
                    <div className="payment_row my-3">
                            <div className="form-group">
                                <label className="text-start" htmlFor="">{t("login.mobile_email")}</label>
                                <input type="text" className="form-control" onChange={(e) => setEmailMobile(e.target.value)} />
                            </div>
                            
                        {/* <p className="mb-2 pb-3 d-block">{t("store.register.info")}</p> */}
                                    <p className="text-danger">{error}</p>
                                    <Button inner={t("login.login")} color="primary mt-2" isfullWidth  isLoading={loading} onClick={login} />
                                    {/* color="primary mt-2" isfullWidth  isLoading={loading} /> */}
                        
                        </div>
                </div>
                </div>
            </div>
             <Modal show={state === "otp"} onHide={() => setState("register")} className="modal_store_info" style={
                {
                    direction: i18n.language === "ar" ? "rtl" : "ltr"
                }
            }>
                <Modal.Header closeButton  style={{
                        border: "none",
                        padding: "0",
                        width: "100%"
                    }}>
                <Modal.Title>{t('store.otp.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OtpForm setState={setState} method={method} to={to} />
                </Modal.Body>
            </Modal>
        </div>
    );
};



export default Wrapper;
const OtpForm = ({setState, method, to} : {setState: any, method: string, to: string | null}) => {
    const {t, i18n} = useTranslation();
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [otp, setOtp] = useState(["", "", "", ""]);
    const inputRefs = useRef([...Array(4)].map(() => React.createRef())) as any;
    const user = JSON.parse(localStorage.getItem('tygo_user') || '{}');
    const navigate = useNavigate();
    const [counter, setCounter] = useState<number>(60);
    const handleOtpChange = (index: number, value: string) => {
      if (value === "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
  
        if (index > 0) {
          inputRefs.current[index - 1].current.focus();
        }
      } else if (+value >= 0 && +value <= 9) {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
  
        if (index < otp.length - 1) {
          inputRefs.current[index + 1].current.focus();
        }
      }
        if (index === otp.length - 1 && value !== "") {
            const otpValue = otp.join("");
            console.log(otpValue);
            const newOtp = [...otp];
            newOtp[index] = value;
         
            verify(newOtp.join(""));
        }
    };
    const [otptype, setOtpType] = useState<string>("PhoneNumber");
    const handleKeyDown = (index: number, e: any) => {
      if (e.key === "Backspace" && index > 0 && otp[index] === "") {
        e.preventDefault();
        setOtp((prevOtp) => {
          const newOtp = [...prevOtp];
          newOtp[index - 1] = "";
          return newOtp;
        });
        inputRefs.current[index - 1].current.focus();
      }
    };
    const verify = async (str: string) => {

        setLoading(true);
        await verifyCode(str, method
        ).then((res) => {
            console.log(res);
            if(res.status === 200) {
                const user = JSON.parse(localStorage.getItem("tygo_user")!);
                user.token = res.data.token;
                user.userId = res.data.userId;
                user.tokenExpiryDate = res.data.tokenExpiryDate;
                localStorage.setItem("tygo_user", JSON.stringify(user));
        
                // setState("card");
                navigate(to || "/");
            }
            setLoading(false);
        }
        ).catch((err) => {
            setError(err.response.data.Message);
            setLoading(false);
        }
        );
    }
    // Email
    const Resend = async (typw:string) => {
        const user = JSON.parse(localStorage.getItem("tygo_user")!);
        // createAccount({
        //     firstName:user.name.split(" ")[0],
        //     gander:"Male",
        //     countryCode: "966",
        //     email:user.email,
        //     phoneNumber:user.phoneNumber.toString().replace(/^0+/, ''),
        //     registertionType: typw,
        // })
        loginAccount({
          
                    email:user.email,
                    phoneNumber:user.phoneNumber.toString().replace(/^0+/, ''),
                    countryCode: "966",
                    logintType: typw,}).then(e=>{
                        toast.success(t("store.otp.message"));
                    })
                
        
        .then(e=>{
            toast.success(t("store.otp.message"));
        })
        .catch(
            (err) => {
                // loginAccount({
                //     email:user.email,
                //     phoneNumber:user.phoneNumber.toString().replace(/^0+/, ''),
                //     countryCode: "966",
                //     logintType: typw,}).then(e=>{
                //         toast.success(t("Otp Has been sent"));
                //     })
                // })
                // .catch((err) => {
                    setError(err.response.data.message);
                }
            );

    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCounter((prev) => {
                if(prev === 0) {
                    clearInterval(interval);
                    return 60;
                }
                return prev - 1;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);


    return (
        <div className="card_form">
            <div className="form-group">
            
            <p>{t("store.otp.info")} {method === "PhoneNumber" ? user.phoneNumber : user.email}</p>
                <div className="my-3 otp_inputs">
                {otp.map((digit, index) => (
        <input
        //   autoFocus={index === 0 ? true : undefined}
        autoFocus={index === 0 ? true : undefined}
          key={index}
          type="number"
          className="form-control"
          maxLength={1}
          value={digit}
          ref={inputRefs.current[index]}
          onKeyDown={(e) => handleKeyDown(index, e)}
          onChange={(e) => handleOtpChange(index, e.target.value)}
        />
      ))}
            
                </div>
            </div>
            <p className="text-danger">{error}</p>
            <div className="row my-4">
                {/* <div className="col-md-6">
                    <Button inner={
                        (counter !== 60 ? counter.toString()+ " "+ t("store.otp.s_to")
                        : "")+ " "+ t("store.otp.resend")}
                         color="secondary" isfullWidth isDisabled={counter!==60}
                     onClick={()=>{
                        Resend("PhoneNumber");
                        const interval = setInterval(() => {
                            setCounter((prev) => {
                                if(prev === 0) {
                                    clearInterval(interval);
                                    return 60;
                                }
                                return prev - 1;
                            });
                        }, 1000);
                        return () => clearInterval(interval);
                        }}
                        />
                </div>
                <div className="col-md-6">
                    <Button inner={t("store.otp.to_email")}
                     color="secondary" isfullWidth onClick={()=>{
                        setOtpType("Email");
                        Resend("Email");
                    }} />
                </div> */}
            </div>
            <Button inner={t("store.otp.submit")}
             color="primary" isfullWidth onClick={
                () => {
                    const otpValue = otp.join("");
                    verify(otpValue);
                }
             }
             isLoading={loading} />
        </div>
    );

}