import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/design/buttons/Button';
import Card from '../../components/design/cards/card';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import SelectType from './pages/SelectType';
import Providers from './pages/Providers';
import Plans from './pages/Plans';
import Login from './pages/Login';
import IdentitiyCheck from './pages/IdentitiyCheck';
import NumberTpye from './pages/NumberType';
import NumberCostumOrListOrTransfer from './pages/NumberCostumOrListOrTransfer';
import Check from './pages/Check';
import './Page.css'
import { Info } from '../store/components/PlanItem';
import { ShimmerTitle } from "react-shimmer-effects-18";
import SideInfo from '../store/components/sideInfo';
import { getPlanbyId } from "../../services/stepper.service";
import { getProvider } from '../../services/providers.service';
import { getResellerPlanId } from '../../services/sim.service';
import Payment from './pages/Payment';

interface ObjectArray {
    onOptionSelect: (obj: { [key: string]: any }) => void;
    t: any;
    i18n: any;
    objs?: any;
}

const Page = () => {
    const { t, i18n } = useTranslation();
    const [currentStep, setCurrentStep] = useState(0);
    const [objs, setObjs] = useState<any>({ number_type: 'new' });
    const [isLoading, setIsLoading] = useState(true);
    const [plan, setPlan] = useState<any>();
    const [provider, SetProvider] = useState<any>();
    const token = localStorage.getItem('tygo_user');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const type = urlParams.get('type');
        const planId = urlParams.get('id');
        const checkoutId = urlParams.get('checkoutId');
        
        if (checkoutId) {
            setObjs(prevObjs => {
                const newObjs = { ...prevObjs, checkoutId };
                return newObjs;
            });
            //go to payment page
            setCurrentStep(3);
        }
        
        const fetchPlan = async () => {
            getPlanbyId(planId, i18n.language).then(async (a: any) => {

                setPlan(a.data.returnData);
                const providerId = a.data.returnData.providerId;
                const provider = await getProvider(providerId, i18n.language);
                SetProvider(provider.filter((a: any) => a.id === providerId)[0]);
                const plan = a.data.returnData;
                const plan_id = (await getResellerPlanId(plan.id)).returnData.resellerPlanId;
                setObjs(prevObjs => {
                    const newObjs = { ...prevObjs, type, plan, providerId, plan_id}
                    return newObjs;
                });
                setIsLoading(false);
            }).catch((e: any) => {
                console.log(e);
            });
        };


        fetchPlan();
    }, [i18n.language]);


    const steps = [
        { component: NumberCostumOrListOrTransfer, title: t('local_sim.number.new.title') },
        { component: IdentitiyCheck, title: t('steps.personal_info') },
        { component: Check, title: t('steps.personal_info') },
        { component: Payment, title: t('steps.personal_info') },
    ];

    const addToObjs = (obj: any) => {
        setObjs(prevObjs => {
            const newObjs = { ...prevObjs, ...obj };
            return newObjs;
        });
        handleNext();
    };

    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(prev => prev + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(prev => prev - 1);
        }
    };

    const progress = ((currentStep + 1) / steps.length) * 100;
    if (!token) {
        window.location.href = '/login2?redirect=/sim?=29';
        return <>   </>
    }

    const handleBack = () => {
        setCurrentStep(prev => prev - 1);
    }
    return (
        <div className="page-container " style={{minHeight:"100vh",display:"flex", flexDirection:"column"}}>
            <Header />
            <div className="container h-100">
                <div className="row">
                    <div className="col-12 col-lg-8 h-100">
                        {
                            isLoading ? (
                                <div className='bg-white'>
                                        <div className="d-flex justify-content-center align-items-center h-100">
                                        <ShimmerTitle line={2} gap={10} variant="primary" />
                                    </div>
                                </div>
                            ) : currentStep > 0 && (
                                <div className="p-0 d-flex gap-4" onClick={handleBack}>
                                    <Button color="d-block p-0"
                                    isfullWidth={false}  inner={
                                        <svg style={{transform: i18n.language == "ar" ? "scaleX(-1)" : "scaleX(1)"}} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.95 31.3669C16.2666 31.3669 16.5833 31.2502 16.8333 31.0002C17.3166 30.5169 17.3166 29.7169 16.8333 29.2335L7.59997 20.0002L16.8333 10.7669C17.3166 10.2835 17.3166 9.48353 16.8333 9.0002C16.35 8.51686 15.55 8.51686 15.0666 9.0002L4.94996 19.1169C4.46663 19.6002 4.46663 20.4002 4.94996 20.8835L15.0666 31.0002C15.3166 31.2502 15.6333 31.3669 15.95 31.3669Z" fill="#292D32"/>
                                            <path d="M6.1165 21.25H34.1665C34.8498 21.25 35.4165 20.6833 35.4165 20C35.4165 19.3167 34.8498 18.75 34.1665 18.75H6.1165C5.43317 18.75 4.8665 19.3167 4.8665 20C4.8665 20.6833 5.43317 21.25 6.1165 21.25Z" fill="#292D32"/>
                                        </svg>
                                    } 
                                    />
                                    <Button color="d-block p-0"
                                    isfullWidth={false}  inner={
                                        <span>{t('global.back')}</span>
                                    } 
                                    />
                                </div>
                        
                            )}

                        {
                            !isLoading ? (
                               <>
                                <h1 className="step-title mt-4">{steps[currentStep].title}</h1>
                        <div className="progress-container mb-4 d-flex mt-4">
                            {steps.map((_, index) => (
                                <div 
                                    key={index} 
                                    className={`progress-capsule ${index <= currentStep ? 'completed' : ''}`} 
                                    style={{ flex: 1, margin: '0 4px', height: '8px', borderRadius: '5px', backgroundColor: index <= currentStep ? '#3EB6AE' : 'lightgray' }}
                                ></div>
                            ))}
                        </div>
                        <div className="" style={{height:"100%"}}>
                            {React.createElement(
                                steps[currentStep].component as React.ComponentType<ObjectArray>,
                                { onOptionSelect: addToObjs as any, t , i18n, objs }
                            )}
                                </div></>
                            ) : (
                                <div className="d-flex justify-content-center align-items-center h-100">
                                    <ShimmerTitle line={2} gap={10} variant="primary" />
                                </div>
                            )
                        }
                    </div>
                    <div className="col-12 col-lg-4">
                        <SideInfo 
                            background={plan ? plan.imageUrl:""}
                            is_bg={true}
                            elements={[
                                <div className="plan-item m-0 p-0">
                                    {
                                        !isLoading ?
                                        <>
                                            <h3>{plan.name}</h3>
                                            <span className='price_item'>{plan.price} {t('global.sar')}</span>
                                        </> :
                                        <ShimmerTitle line={2} gap={10} variant="primary" />
                                    }
                                    <div className="d-flex gap-2 mt-4">
                                        {
                                            !isLoading ?
                                            <Info name={t("details.type")} logo="/media/svgs/clock_.svg" info={t(plan.contractTypeId === 1 ? t("details.prepaid") : t("details.postpaid"))} /> :
                                            <div className="w-100">
                                                <ShimmerTitle line={2} gap={10} variant="primary" />
                                            </div>
                                        }
                                        {
                                            !isLoading ?
                                            <Info name={t("details.provider")} logo="/media/svgs/rss.svg" info={provider ? provider.name :""} /> :
                                            <div className="w-100">
                                                <ShimmerTitle line={2} gap={10} variant="primary" />
                                            </div>
                                        }
                                    </div>
                                </div>,
                                <Card 
                                noEdge
                                inner={<SelectedOptions objs={objs} t={t} />}
                                title={t('store.order_details')}
                                title_icon="/media/svgs/list.svg"
                                title_color="primary"
                                />,
                                <div className="table_info">
                                    <div className="d-flex title_item align-items-center gap-2">
                                        <img src="/media/svgs/list.svg" />
                                        <h6 className='m-0'>{t('store.order_details')}</h6>
                                    </div>
                                    <div className="d-flex justify-content-between table_item">
                                        <span>{t('store.price')}</span>
                                        <span>{plan?.price} {t('global.sar')}</span>
                                    </div>
                                    <div className="d-flex justify-content-between table_item">
                                        <span>{t('store.quantity')}</span>
                                        <span>1</span>
                                    </div>
                                    <div className="d-flex justify-content-between table_item">
                                        <span>{t('store.fees')}</span>
                                        <span>0 {t('global.sar')}</span>
                                    </div>
                                    <div className="devider"></div>
                                    <div className="d-flex justify-content-between ">
                                        <span>{t('store.total')}</span>
                                        <strong className='c-primary'>{plan?.price} {t('global.sar')}</strong>
                                    </div>
                                </div>
                                
                                
                                ]} noBg={[1]}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Page;
export type { ObjectArray };

interface SelectedOptionsProps {
    objs: any[];
    t: any;
}

const SelectedOptions: React.FC<SelectedOptionsProps> = ({ objs, t }) => {
    console.log(objs);
    return (
        <div>
            <div className="table_info ">
            <div className="d-flex title_item align-items-center gap-2">
                <img src="/media/svgs/list.svg" />
                <h6 className='m-0'>{t('local_sim.title')}</h6>
                </div>
            </div>
            <ul className="selected-options-list d-flex flex-column gap-2 mt-4 mb-0">
                {Object.entries(objs).map(([key, value], index) => (
                    (key === 'type' || key === 'phone') && (
                        <li key={index} className="d-flex justify-content-between">
                            <span>{t(`local_sim.identity_check.${key}`)}</span>
                            <span>{String(value)}</span>
                        </li>
                    )
                ))}
            </ul>
        </div>
    );
};
