


const REACT_APP_API_PROVIDERS: string = window.REACT_APP_API_PROVIDERS || '';
const REACT_APP_API_ESIM: string = window.REACT_APP_API_ESIM || '';
const REACT_APP_API_SIM: string = window.REACT_APP_API_SIM || '';
const REACT_APP_API_ACCOUNTS: string = window.REACT_APP_API_ACCOUNTS || '';
const REACT_APP_API_PAYMENT: string = window.REACT_APP_API_PAYMENT || '';
const REACT_APP_API_PAYMENTS: string = window.REACT_APP_API_PAYMENTS || '';
const REACT_APP_API_AUCTIONS: string = window.REACT_APP_API_AUCTIONS || '';
export {
  REACT_APP_API_PROVIDERS,
  REACT_APP_API_ESIM,
  REACT_APP_API_SIM,
  REACT_APP_API_ACCOUNTS,
  REACT_APP_API_PAYMENT,
  REACT_APP_API_PAYMENTS,
  REACT_APP_API_AUCTIONS
}