import React, { useState } from 'react';
import { ObjectArray } from '../Page';
import { ButtonSquare } from '../../../components/design/buttons/Button';
import CountryCodeSelector from '../../stepper/steps/SelectCountyCode';

const IdentitiyCheck: React.FC<ObjectArray> = ({ onOptionSelect, t, i18n }) => {
    // const [identityError, setIdentitiyError] = useState<string>();
    // const [identityNumberError, setIdentitiyNumberError] = useState<string>();
    const [number, setNumber] = useState<string>('');
    // const [identity, setIdentitiy] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [dateOfBirth, setDateOfBirth] = useState<string>('');
    const [selectedCode, setSelectedCode] = useState('966');
    // errors
    const [firstNameError, setFirstNameError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [errorNumber, setErrorNumber] = useState<string>('');
    const [dateOfBirthError, setDateOfBirthError] = useState<string>('');
    const [selectedCodeError, setSelectedCodeError] = useState<string>('');



    const identities = [
        { id: 1, name: "Kimlik" },
        { id: 2, name: "Pasaport" },
        { id: 3, name: "Sürücü Belgesi" }
    ];
    const onClick = () => {
        let hasError = false;

        if (!firstName) {
            setFirstNameError(t("local_sim.identity_check.errors.required"));
            hasError = true;
        } else if (firstName.length < 2 || firstName.length > 40 || /\d/.test(firstName)) {
            setFirstNameError(t("local_sim.identity_check.errors.invalid_name"));
            hasError = true;
        } else {
            setFirstNameError('');
        }

        if (!email) {
            setEmailError(t("local_sim.identity_check.errors.required"));
            hasError = true;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError(t("local_sim.identity_check.errors.invalid_email"));
            hasError = true;
        } else {
            setEmailError('');
        }

        if (!dateOfBirth) {
            setDateOfBirthError(t("local_sim.identity_check.errors.required"));
            hasError = true;
        } else if (new Date(dateOfBirth) > new Date()) {
            setDateOfBirthError(t("local_sim.identity_check.errors.future_date"));
            hasError = true;
        } else {
            setDateOfBirthError('');
        }

        if (!number) {
            setErrorNumber(t("local_sim.identity_check.errors.required"));
            hasError = true;
        } else if (!/^(0\d{9}|5\d{8})$/.test(number)) {
            setErrorNumber(t("local_sim.identity_check.errors.invalid_phone"));
            hasError = true;
        } else {
            setErrorNumber('');
        }

        if (hasError) {
            return;
        }

        const obj = {
            firstName,
            email,
            dateOfBirth,
            number,
            selectedCode
        };
        onOptionSelect(obj);
    };


    return (
        <div className="d-flex flex-column gap-3">
            <div className="form-group">
                <label htmlFor="">{t("local_sim.identity_check.name")}</label>
                <div className='input_bg d-flex gap-3 align-items-center mt-2' style={{padding:"10px"}}>
                    <input
                        className={`form-control form-control-lg${firstNameError ? " is-invalid" : ""}`}
                        style={{ fontSize: '1.25rem' }}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </div>
                {firstNameError && <p className="text-danger">{firstNameError}</p>}
            </div>

            <div className="form-group">
                <label htmlFor="">{t("local_sim.identity_check.email")}</label>
                <div className='input_bg d-flex gap-3 align-items-center mt-2' style={{padding:"10px"}}>
                    <input
                        type="email"
                        className={`form-control form-control-lg${emailError ? " is-invalid" : ""}`}
                        style={{ fontSize: '1.25rem' }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                {emailError && <p className="text-danger">{emailError}</p>}
            </div>

            <div className="form-group">
                <label htmlFor="">{t("local_sim.identity_check.date_of_birth")}</label>
                <div className='input_bg d-flex gap-3 align-items-center mt-2' style={{padding:"10px"}}>
                    <input
                        type="date"
                        className={`form-control form-control-lg${dateOfBirthError ? " is-invalid" : ""}`}
                        style={{ fontSize: '1.25rem' }}
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                    />
                </div>
                {dateOfBirthError && <p className="text-danger">{dateOfBirthError}</p>}
            </div>
            <div className="form-group ">
                <label htmlFor="">{t("local_sim.identity_check.phone")}</label>
                <div className="d-flex gap-3 align-items-center input_bg mt-2 " dir='rtl'>
                    <input
                        className={`form-control form-control-lg${errorNumber ? " is-invalid" : ""}`}
                        style={{ fontSize: '1.25rem',  border:0}}
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                    />
                    <CountryCodeSelector selectedCode={selectedCode} onCodeChange={setSelectedCode} />
                </div>
                {errorNumber && <p className="text-danger">{errorNumber}</p>}
            </div>
            <p>{t("local_sim.identity_check.otp_msg")}</p>
            <div className="mt-2">
                <ButtonSquare 
                    inner={
                        <div className='mx-5'>{t("local_sim.identity_check.button")}</div>
                    }
                    color="primary px-5"
                    // isfullWidth={true}
                    isrounded={true}
                    onClick={onClick}
                />
            </div>
        </div>
    );
};

export default IdentitiyCheck;
