import logo from '../logo.svg';
import './App.css';
import Stepper from './pages/stepper/Stepper';
import 'react-toastify/dist/ReactToastify.css';

// function App() {
//   return (
//     <div className="App">
//       {/* <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header> */}

//       <div className='bg-elements'>
//         <div className='bg-element bg-element--1'></div>

//       </div>
      

//       <Stepper />
//     </div>
//   );
// }

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, Navigate } from 'react-router-dom';
import PlanScreen from './pages/plans/details/plan';
import AllPlansScreen from './pages/plans/all/plans';
import { ToastContainer } from 'react-toastify';
import RecommandedPlans from './pages/plans/recomended/recomendedPlans';
import AddToCart from './pages/plans/components/test';
import Test2 from './pages/plans/components/test2';
import CompareCardProvider from './components/compare/CompareCard';
import CompareScreen from './pages/plans/compare/compare';
import CompareAdvScreen from './pages/plans/compare_adv/compare_adv';
import { useTranslation } from 'react-i18next';
import { EsimsScreen } from './pages/esims/esims';
import { LandingScreen } from './pages/landing/landing';
import IntercomComponent from './IntercomComponent';

// export const LangContext = React.createContext(null as any);


// const LangProvider = ({ children }: any) => {
//   const [lang, setLang] = React.useState('en');
//   return (
//     <LangContext.Provider value={{ lang, setLang }}>
//       <div dir={lang === 'ar' ? 'rtl' : 'ltr'} className="h-100"
//       >{children}</div>
//     </LangContext.Provider>
//   );
// };
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AboutUs from './pages/about/About';
import Services from './pages/about/Services';
import Blog from './pages/blog/blog';
import Post from './pages/blog/past';
import Professionals from './pages/professionals/Professionals';
import Terms from './pages/terms/terms';
import Store, { PlanDetails } from './pages/store/Store';
import CompareOrderProvider from './components/compare/OrderCard';
import Order from './pages/order/Order';
import Privacy from './pages/privacy/privacy';
import PageNotFound from './pages/404/404';
import CreateOrder from './pages/order/create/Create';
import Payment from './pages/store/components/Payment';
import Regulations from './pages/regulations/regulations';
import Auction from './pages/auction/Auction';
import AuctionDetails from './pages/auction/details/AuctionDetails';
import AuctionTerms from './pages/auctionTerms/terms';
import Login from './pages/auth/Login';
import TagManager from 'react-gtm-module';
import Page from './pages/localSim/Page';
import SignIn from './pages/sign_in/SignIn';
// <!-- <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K9S7BWCK" 

const tagManagerArgs = {
  gtmId: 'GTM-K9S7BWCK'
};

function ScrollToTop() {
  const { pathname } = useLocation();
  const {t,i18n} = useTranslation();

  useEffect(() => {
    if(localStorage.getItem('lang')==null)
      localStorage.setItem('lang', navigator.language.split("-")[0] == 'ar' ? 'ar' : 'en');

    document.title =  (localStorage.getItem('lang') == 'ar' ? "تايقو - " : "Tygo - ")  + (pathname.split("/")[1] ? pathname.split("/")[1] : localStorage.getItem('lang') == 'ar' ? 'الرئيسية' : 'Home');
    // alert(document.title);
    window.scrollTo(0, 0);
    i18n.changeLanguage(localStorage.getItem('lang') == 'ar' ? 'ar' : 'en');
  }, [pathname]);

  return null;
}


const App = () => {
    const {t,i18n} = useTranslation();
    useEffect(() => {
      TagManager.initialize(tagManagerArgs);
    }, []);
  
    useEffect(() => {

    }, [i18n.language]);

    return (
    <div dir={i18n.language == 'ar' ? 'rtl' : 'ltr'} style={{minHeight: "100%"}}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/ar/:path" element={<LangChanger lang="ar" />} />
          <Route path="/en/:path" element={<LangChanger lang="en" />} />
          <Route path="/stepper/:step" element={<Stepper />} />
          {/* <Route path="/test" element={<AddToCart elementId={''} />} /> */}
        </Routes>
        <CompareCardProvider>
          <CompareOrderProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/auctionTerms" element={<AuctionTerms />} />
              <Route path="/plans" element={<AllPlansScreen />} />
              <Route path="/plans/:id" element={<PlanScreen />} />
              <Route path="/sim" element={<Page />} />
              <Route path="/login2" element={<SignIn />} />
              <Route path="/store" element={<Store />} />
              <Route path="/order" element={<Order />} />
              <Route path="/auction" element={<Auction />} />
              <Route path="/auction/:id" element={<AuctionDetails />} />
              <Route path="/order/create" element={<CreateOrder />} />
              <Route path="/store/:id" element={<Store />} />
              <Route path="/store/:id/:item" element={<Payment />} />
              <Route path="/recommended" element={<RecommandedPlans />} />
              <Route path="/recommended/:id" element={<RecommandedPlans />} />
              <Route path="/compare_" element={<CompareAdvScreen/>} />
              <Route path="/compare" element={<CompareScreen/>} />
              <Route path="/compare/:id" element={<CompareScreen/>} />
              <Route path="/esims" element={<EsimsScreen />} />
              <Route path='/about' element={<AboutUs />} />
              <Route path="/details" element={
                <div className='container'>

                  <PlanDetails />
                </div>
              } />
              <Route path="/services" element={<Services />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/post" element={<Post />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/regulations" element={<Regulations/>} />

              <Route path="/professionals" element={<Professionals />} />

              <Route path="/stepper/:step" element={< ></>} />

              <Route path="/" element={<LandingScreen />} />
              {/* <Route path="/" element={<Navigate to="/stepper/1" />} /> */}
              <Route path="/404" element={<PageNotFound />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </CompareOrderProvider>
        </CompareCardProvider>
        
        <ToastContainer />
        {/* <IntercomComponent /> */}
      </Router>

    </div>
    );
  
}

const LangChanger = ({ lang }: any) => {
  //set i18n language and redirect from /ar/.. to /..
  const { i18n } = useTranslation();
  const url = window.location.pathname.replace('/' + lang, '');
  useEffect(() => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
  }, [lang]);

  return <Navigate to={url} />;
}



export default App;
