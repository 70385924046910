import { useEffect, useRef, useState } from "react";
import Button from "../../../components/design/buttons/Button";
// import './Payment.css'
import { Card, Collapse, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { REACT_APP_API_ESIM, REACT_APP_API_PAYMENT, REACT_APP_API_PAYMENTS } from '../../../../config';
import axios from "axios";
import { handleDataLayer } from "../../../tools/AssetHelpers";
import { ObjectArray } from "../Page";
import { checkout, generateCheckoutId } from "../../../services/sim.service";

const Payment: React.FC<ObjectArray> = ({ onOptionSelect, t, i18n, objs }) => {
    // const [selected, setSelected] = useState(0);
    const navigate = useNavigate();
    // const [state, setState] = useState<any>("register");
    const isMobile = window.innerWidth < 992;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // const [plan, setPlan] = React.useState<any>(null);
    const [user, setUser] = useState<any>(localStorage.getItem("tygo_user"));
    const [loading, setLoading] = useState<boolean>(false);
    const [checkoutIds, setCheckoutIds] = useState<any>({
        'VISA': "",
        'APPLEPAY': "",
        'STC_PAY': ""
    });
    const [paymentMethod, setPaymentMethod] = useState<string>(isSafari ? "APPLEPAY" : "VISA");
    const [show, setShow] = useState<boolean>(false);
    const [FoundForPaymentButton, setSearchedForPaymentButton] = useState<boolean>(false);
    const [FoundForCVVafterButton, setSearchedForCVVafterButton] = useState<boolean>(false);
    const [showCancel, setShowCancel] = useState<boolean>(false);

    var wpwlOptions = {
        locale: "ar",
        applePay: {
            displayName: "Tygo",
            total: {label: "Tygo"},
        }
    };

    useEffect(() => {
        setUser(localStorage.getItem("tygo_user"));
        if(user && JSON.parse(user).token) {
            handleDataLayer("payment_page", "user", "user_visited_payment_page", "user_visited_payment_page");
        } else {
            handleDataLayer("login_page", "user", "user_visited_login_page", "user_visited_login_page");
        }
    }, []);

    const GenerateCheckoutId = async () => {
        setLoading(true);
        console.log(objs);
        
        // const body = {
        //     "email": (objs?.email || ""),
        //     "street": (objs?.street || ""),
        //     "city": (objs?.city || ""),
        //     "state": (objs?.state || ""),
        //     "country": (objs?.country || ""),
        //     "postcode": (objs?.postcode || ""),
        //     "givenName": (objs?.name || "").replace(/\s/g, ''),
        //     "surname": (objs?.surname || ""),
        //     "phoneNumber": (objs?.phoneNumber || ""),
        //     "countryCode": (objs?.countryCode || ""),
        //     "amount": (objs?.plan?.price || 0),
        //     "entityType": paymentMethod,
        //     "userId": (user ? user.userId : ""),
        //     "paymentFor": "string",
        //     "productIds": ((objs?.plan_id).toString() || ""),
        //     "productNames": (objs?.plan?.name || ""),
        //     "tygoOrderId": (objs?.plan?.id || "").toString(),
        //     "promoCode": "",
        //     "deviceType": "string",
        //     "deviceVersion": "string"
        // }
        const body1 = {
            "productIds":objs?.plan.id.toString(),
            "productNames":"Solo 240",
            "tygoOrderId":objs?.tygoOrderId,
            "email":"malhabbash6@gmail.com",
            "productId":438,
            "street":"test",
            "city":"Riyadh",
            "state":"Riyadh",
            "country":"SA",
            "postcode":"11375",
            "givenName":"Mohammed",
            "surname":"Alhabbash",
            "phoneNumber":"0541599105",
            "countryCode":"966",
            "amount":276.0,
            "entityType":"VISA",
            "userId":"8dad0f88-5bff-44e2-a2cd-fb3e3dc6831c",
            "paymentFor":null,
            "promoCode":"",
            "planId":438
        }

        const response = await generateCheckoutId(body1, objs.providerId);
        const temp = checkoutIds;
        console.log(response);
        temp[paymentMethod] = response.hyperpayCheckoutId;
        setCheckoutIds(temp);
        setLoading(false);
    };

    useEffect(() => {
        if (objs && paymentMethod) {
            GenerateCheckoutId();
        }
    }, [objs, paymentMethod]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCancel = () => {
        setShowCancel(false);
        navigate("/esims");
    }

    const LaunchSearchForPaymentButton = () => {
        const terms_for_card = document.querySelector(".terms_for_card");
        if (!FoundForPaymentButton) {
            setTimeout(() => {
                const paymentButton = document.querySelector(".wpwl-button-pay");
                if (paymentButton) {
                    if (terms_for_card) {
                        if (paymentButton && paymentButton.parentNode) {
                            paymentButton.parentNode.insertBefore(terms_for_card, paymentButton);
                        }
                    }
                    setSearchedForPaymentButton(true);
                } else {
                    console.log("Payment button not found");
                    LaunchSearchForPaymentButton();
                }
            }, 1000);
        }
    };

    const LaunchSearchForCVVafterButton = () => {
        if (!FoundForCVVafterButton) {
            setTimeout(() => {
                const cvv_label = document.querySelector(".wpwl-label-cvv");
                if (cvv_label) {
                    cvv_label.addEventListener("click", handleShow);
                    cvv_label.setAttribute("style", "cursor: pointer;");
                    setSearchedForCVVafterButton(true);
                } else {
                    console.log("CVV not found");
                    LaunchSearchForCVVafterButton();
                }
            }, 1000);
        }
    };

    useEffect(() => {
        if (checkoutIds[paymentMethod]) {
            console.log(checkoutIds[paymentMethod], "--------------checkoutIds[paymentMethod]");
            const script = document.createElement("script");
            const shopperResultUrl = window.location.origin + "/order/create" + "?id=" + checkoutIds[paymentMethod];
            script.src = `https://`+(REACT_APP_API_ESIM === "https://test.esim.tygo.net" ? "test." : "")+`oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutIds[paymentMethod]}`;
            script.async = true;
            script.setAttribute("data-brands", "VISA MASTER APPLEPAY STC_PAY");
            script.setAttribute("data-options", JSON.stringify(wpwlOptions));
            document.body.appendChild(script);
            LaunchSearchForPaymentButton();
            LaunchSearchForCVVafterButton();
        }
    }, [checkoutIds[paymentMethod], paymentMethod]);

    return (
        <div className="cko-payment-form" data-checkout-id={checkoutIds[paymentMethod]}>
            <>
                <div className={"card card_total"}>
                    <span>{t("store.total")}</span>
                    <span className="price_total">{objs?.plan.price} {t("global.sar")}</span>
                </div>
                <div className={isSafari ? "card mb-3" : "bg-gray card mb-3"}
                    onClick={() => isSafari ? setPaymentMethod("APPLEPAY") : null}>
                    <div className="header_card w-100 d-flex justify-content-between align-items-center">
                        <span className="big-text">
                            Pay
                        </span>
                        <span>
                            {!isSafari && <span>{t("store.supported")}</span>}
                            {paymentMethod === "APPLEPAY" ?
                                <i className="fas fa-chevron-up mx-2"></i> :
                                <i className="fas fa-chevron-down mx-2"></i>
                            }
                        </span>
                    </div>
                    <Collapse in={paymentMethod === "APPLEPAY"}>
                        <div id="collapseAPPLEPAY">
                            <div className="paymentMethod" id="paymentWidgets_apple">
                                <span className="terms_ terms_for_card_y">
                                    <span>{t("store.by_continuing")} </span>
                                    <span><a className="m-0" href="https://tygo.sa/en/terms" target="_blank">{t("store.terms")}</a></span>
                                    <span> {t("store.and")} </span>
                                    <span><a className="m-0" href="https://tygo.sa/en/privacy" target="_blank">{t("store.privacy_policy")}</a>.</span>
                                </span>
                                <form action={window.location.origin + "/order/create" + "?id=" + checkoutIds[paymentMethod]} className="paymentWidgets" data-brands="APPLEPAY"></form>
                            </div>
                        </div>
                    </Collapse>
                </div>

                <div className="card mb-3" onClick={() => setPaymentMethod("VISA")}>
                    <div className="header_card w-100 d-flex justify-content-between align-items-center">
                        <span>{t("store.via_bank_card")}</span>
                        <span>
                            {paymentMethod === "VISA" ?
                                <i className="fas fa-chevron-up mx-2"></i> :
                                <i className="fas fa-chevron-down mx-2"></i>
                            }
                        </span>
                    </div>
                    <Collapse in={paymentMethod === "VISA"}>
                        <div id="collapseVISA">
                            <div className="paymentMethod" id="paymentWidgets_visa">
                                <form action={window.location.origin + "/order/create" + "?id=" + checkoutIds[paymentMethod]} className="paymentWidgets" data-brands="VISA"></form>
                            </div>
                            <span className="terms_ terms_for_card">
                                <span>{t("store.by_continuing")} </span>
                                <span><a className="m-0" href="https://tygo.sa/en/terms" target="_blank">{t("store.terms")}</a></span>
                                <span> {t("store.and")} </span>
                                <span><a className="m-0" href="https://tygo.sa/en/privacy" target="_blank">{t("store.privacy_policy")}</a>.</span>
                            </span>
                        </div>
                    </Collapse>
                </div>

                <div className="card mb-3" onClick={() => setPaymentMethod("STC_PAY")}>
                    <div className="header_card w-100 d-flex justify-content-between align-items-center">
                        <span>{t("store.via_stc")}</span>
                        <span>
                            {paymentMethod === "STC_PAY" ?
                                <i className="fas fa-chevron-up mx-2"></i> :
                                <i className="fas fa-chevron-down mx-2"></i>
                            }
                        </span>
                    </div>
                    <Collapse in={paymentMethod === "STC_PAY"}>
                        <div id="collapseSTC_PAY">
                            <div className="paymentMethod" id="paymentWidgets_stc">
                                <form action={window.location.origin + "/order/create" + "?id=" + checkoutIds[paymentMethod]} className="paymentWidgets" data-brands="STC_PAY"></form>
                            </div>
                        </div>
                    </Collapse>
                </div>
            </>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("store.cvv")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                        <div className="d-flex justify-content-center align-items-center">
                            <img src="https://tygo.sa/images/cvv.png" alt="cvv" />
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <span>{t("store.cvv_desc")}</span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="p-3">
                <img src="/media/payment.svg" />
            </div>
        </div>
    );
}

export default Payment;
