import React, { useEffect, useRef, useState } from 'react';
import { ObjectArray } from '../Page';
import { ButtonSquare } from '../../../components/design/buttons/Button';
import { Modal } from 'react-bootstrap';
import { getMetadata } from '../../../services/orders.service';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { checkEligibility, checkout, otpConfirm } from '../../../services/sim.service';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/design/buttons/Button';
import { loginAccount, verifyCode } from '../../../services/accounts.service';


const Check: React.FC<ObjectArray> = ({ onOptionSelect, t , i18n, objs }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [otp, setOtp] = useState<any>();
    const [identityError, setIdentitiyError] = useState<string>();
    const [identityNumberError, setIdentitiyNumberError] = useState<string>();
    const [identityNumber, setIdentitiyNumber] = useState<string>('');
    const [identity, setIdentitiy] = useState<any>();
    const [identities, setIdentities] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [showCountryModal, setShowCountryModal] = useState(false);
    const [showMapModal, setShowMapModal] = useState(false);
    const [location, setLocation] = useState<{ lat: number; lng: number } | null>(null);
    const [locationError, setLocationError] = useState<string>();
    const [otp_type, setOtpType] = useState<string>("PhoneNumber");
    const [otp_method, setOtpMethod] = useState<string>("PhoneNumber");
    const [otp_state, setOtpState] = useState<string>("");

    // const [data_checkout, setDataCheckout] = useState<any>();
    const mapContainerStyle = {
        width: '100%',
        height: '400px'
    };

    const center = {
        lat: 24.7136, // Default to Saudi Arabia center
        lng: 46.6753
    };
    const [rest_obj, setRestObj] = useState<any>();

    const onClick = async () => {
        setLoading(true);
        const obj = {
            identity,
            identity_number: identityNumber,
            location: location ? {
                latitude: location.lat,
                longitude: location.lng
            } : null
        };
        
        console.log(objs);
        const data_form = {
            name: objs.firstName,
            nationality: obj.identity.tcc_Id,
            idNumber: obj.identity_number,
            idType: identityNumber.startsWith('1') ? 1 : 2,
            contractType: (objs.plan.contractTypeId).toString(), 
            customerContactNumber: objs.number,
            lng: obj.location?.longitude.toString(),
            lat: obj.location?.latitude.toString(),
            plan_id: objs.plan_id,
        }

        const data_checkout = {
          
            nationality: {
                id: (1234567890).toString(),
                idType: identityNumber.startsWith('1') ? "1" : "2",
                eligible: true,
                country_id: obj.identity.tcc_Id,
                country_name: obj.identity.nameEN
            },
            number: {
                reservation_id: '',
                identifier: ''
            },
            customer: {
                email: objs.email,
                email_verified: '',
                contact_number_verified: '',
                contact_number: objs.number,
                contact_number_encrypted: '',
                name: objs.firstName,
                visitorOTPType: "PHONE",
                countryCode: objs.selectedCode
            },
            delivery: {
                delivery_lat: location?.lat.toString(),
                delivery_lng: location?.lng.toString(),
                delivery_notes: '',
                delivery_country: '',
                delivery_address: '',
                delivery_city: '',
                delivery_district: '',
                delivery_postalcode: ''
            },
            plan_id: objs.plan_id,
            sim_type: 1,
            numberOrderType: 0,
            mnpOperator: '',
            mnpNumber: ''
        };
        
         
        checkEligibility(data_form, objs.providerId).then((res: any) => {
            if (res.returnData.isEligible) {
                checkout(data_checkout, objs.providerId).then((res: any) => {
                    setOtp(res.returnData.otp);
                    setOtpState("otp");
                    setOtpType(res.returnData.otp.otpFor);
                    setOtpMethod(res.returnData.otp.otpFor);          
                
                    // // res.returnData.otp
                    // // add otp to obj 
                    // obj.otp = res.returnData.otp;
                    // obj.tygoOrderId = res.returnData.tygoOrderId;
                    // obj.checkoutId = res.returnData.checkoutId;
                    const rest_obj__ = {
                        ...objs,
                        otp: res.returnData.otp,
                        tygoOrderId: res.returnData.tygoOrderId,
                        checkoutId: res.returnData.checkoutId
                    }

                    setRestObj(rest_obj__);
                    
                });
            }
            else {
                setError(res.data.returnData.errorMessage);
            }
        }).catch((err: any) => {
            setError(err.response.data.errorMessage);
        });


    };

    

    useEffect(() => {
        const d = async () => {
            try {
                const response = await getMetadata(i18n.language);
                setIdentities(response?.data.returnData);
            } catch (error) {
                console.error('Error fetching metadata:', error);
            }
        };

        d();
    }, []);

    const filteredIdentities = identities.filter(identity =>
        i18n.language === "ar"
            ? identity.nameAr.includes(searchQuery)
            : identity.nameEN.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleMapClick = (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
            setLocation({
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            });
        }
    };

    const handleIdentityNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Only allow digits
        if (/^\d*$/.test(value)) {
            setIdentitiyNumber(value);
            // If ID starts with 1, automatically set nationality to Saudi Arabia
            if (value.startsWith('1')) {
                const saudiIdentity = identities.find(id => id.nameEN === 'Saudi Arabia');
                if (saudiIdentity) {
                    setIdentitiy(saudiIdentity);
                }
            }
        }
    };

    return (
        <div className="d-flex flex-column align-items-center justify-content-center gap-4 w-100">
            {!error && (loading ? (
                <h1 className="text-center">
                    {t("local_sim.check.title")}
                    {loading && <span className="loading-dots" style={{ animationDelay: '0s' }}></span>}
                    {loading && <span className="loading-dots" style={{ animationDelay: '0.2s' }}></span>}
                    {loading && <span className="loading-dots" style={{ animationDelay: '0.4s' }}></span>}
                </h1>
            ) : (
                <div className='w-100'>
                    <div className="form-group">
                        <label htmlFor="">{t("local_sim.identity_check.identity_number")}</label>
                        <div className='input_bg d-flex gap-3 align-items-center mt-2' style={{ padding: "10px" }}>
                            <input
                                type="text"
                                className={`form-control form-control-lg${identityNumberError ? " is-invalid" : ""}`}
                                style={{ fontSize: '1.25rem' }}
                                value={identityNumber}
                                onChange={handleIdentityNumberChange}
                                maxLength={10}
                            />
                        </div>
                        {identityNumberError && <p className="text-danger">{identityNumberError}</p>}
                    </div>
                
                    <div className="form-group">
                        <label htmlFor="">{t("local_sim.identity_check.identity")}</label>
                        <div className='input_bg d-flex gap-3 align-items-center p-4 mt-2' style={{ padding: "10px" }}>
                            <div onClick={() => setShowCountryModal(true)}>
                                {identity ? (
                                    <div className="d-flex align-items-center gap-3">
                                        <img
                                            src={identity.flagImageUrl}
                                            alt={identity.nameEN}
                                            style={{ width: "30px", marginRight: "10px" }}
                                        />
                                        <span>{i18n.language === "ar" ? identity.nameAr : identity.nameEN}</span>
                                    </div>
                                ) : (
                                    <span>{t("local_sim.identity_check.select_identity")}</span>
                                )}
                            </div>
                        </div>
                        {identityError && <p className="text-danger">{identityError}</p>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="">{t("local_sim.identity_check.location")}</label>
                        <div className='input_bg d-flex gap-3 align-items-center p-4 mt-2' style={{ padding: "10px" }}>
                            <div onClick={() => setShowMapModal(true)} style={{ cursor: 'pointer' }}>
                                {location ? (
                                    <span>{`${location.lat.toFixed(6)}, ${location.lng.toFixed(6)}`}</span>
                                ) : (
                                    <span>{t("local_sim.identity_check.select_location")}</span>
                                )}
                            </div>
                        </div>
                        {locationError && <p className="text-danger">{locationError}</p>}
                    </div>

                    <div className="mt-4">
                        <ButtonSquare
                            inner={
                                <div className='mx-5'>{t("local_sim.identity_check.button")}</div>
                            }
                            color="primary px-5"
                            isrounded={true}
                            onClick={onClick}
                        />
                    </div>
                
                    <Modal show={showCountryModal} onHide={() => setShowCountryModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t("local_sim.identity_check.select_identity")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
                            <input
                                type="text"
                                className="form-control mb-3"
                                placeholder={t("local_sim.identity_check.search_placeholder")}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            {filteredIdentities.map((identity) => (
                                <div
                                    key={identity.id}
                                    className="country-option d-flex gap-3 p-3 align-items-center"
                                    onClick={() => {
                                        setIdentitiy(identity);
                                        setShowCountryModal(false);
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <img
                                        src={identity.flagImageUrl}
                                        alt={identity.nameEN}
                                        style={{ width: "30px" }}
                                    />
                                    <span>{i18n.language === "ar" ? identity.nameAr : identity.nameEN}</span>
                                    {identity.id === identity && <span className="ml-2">(Selected)</span>}
                                </div>
                            ))}
                        </Modal.Body>
                    </Modal>

                    <Modal show={showMapModal} onHide={() => setShowMapModal(false)} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>{t("local_sim.identity_check.select_location")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <LoadScript googleMapsApiKey={window.REACT_APP_GOOGLE_MAPS_API_KEY}>
                                <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    center={center}
                                    zoom={6}
                                    onClick={handleMapClick}
                                >
                                    {location && (
                                        <Marker
                                            position={location}
                                        />
                                    )}
                                </GoogleMap>
                            </LoadScript>
                            <div className="mt-3 d-flex justify-content-center">
                                <ButtonSquare
                                    inner={
                                        <div className='mx-5'>{t("local_sim.identity_check.confirm_location")}</div>
                                    }
                                    color="primary px-5"
                                    isrounded={true}
                                    onClick={() => {
                                        if (!location) {
                                            setLocation({ lat: 0, lng: 0 });
                                        }
                                        setShowMapModal(false);
                                    }}
                                />
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            ))}

            {error && <p className="text-danger">{error}</p>}
            {/* {error && <button className='btn btn-primary' onClick={handleCheckout}>{t("local_sim.identity_check.try_again")}</button>} */}
            <Modal show={otp_state === "otp"} onHide={() => setOtpState("")} className="modal_store_info" style={
                {
                    direction: i18n.language === "ar" ? "rtl" : "ltr"
                }
            }>
                <Modal.Header className="order-header" closeButton  style={{
                        border: "none",
                        padding: "0",
                        width: "100%"
                    }}>
                <Modal.Title>{t('store.otp.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OtpForm otp_obj={otp} providerId={objs.providerId} objs={objs} rest_obj={rest_obj} onOptionSelect={onOptionSelect} />
                </Modal.Body>
            </Modal>
            <style>{`
                .loading-dots {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    margin: 0 2px;
                    background-color: black;
                    border-radius: 50%;
                    animation: bounce 1s infinite;
                }

                @keyframes bounce {
                    0%, 100% {
                        transform: translateY(0);
                    }
                    50% {
                        transform: translateY(-10px);
                    }
                }
            `}</style>
        </div>
    );
};

    const OtpForm = ({ otp_obj, objs, providerId , rest_obj, onOptionSelect}: { otp_obj: any, objs: any, providerId: number , rest_obj: any, onOptionSelect: any}) => {
    const { t } = useTranslation();
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [otp, setOtp] = useState<string[]>(Array(4).fill(""));
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const user = JSON.parse(localStorage.getItem('tygo_user') || '{}');
    const [counter, setCounter] = useState<number>(60);

    const navigate = useNavigate();
    
    


    const handleOtpChange = (index: number, value: string) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value === "" && index > 0) {
            inputRefs.current[index - 1].focus();
        } else if (value.match(/^\d$/) && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }

        if (index === otp.length - 1 && value !== "") {
            verify(newOtp.join(""));
        }
    };

    const handleKeyDown = (index: number, e: React.KeyboardEvent) => {
        if (e.key === "Backspace" && index > 0 && otp[index] === "") {
            e.preventDefault();
            setOtp(prevOtp => {
                const newOtp = [...prevOtp];
                newOtp[index - 1] = "";
                return newOtp;
            });
            inputRefs.current[index - 1].focus();
        }
    };

    const verify = async (otpValue: string) => {
        setLoading(true);
        try {
            const res  =await otpConfirm({id: otp_obj.id, code: otpValue}, providerId);
            if (res === false) {
                    setError("Invalid OTP");
                } else {
                    onOptionSelect(rest_obj);
                    // // curl -X POST "https://test.sim.tygo.net/api/v2/Order/14/Checkout" -H  "accept: text/plain; ver=2.0" -H  "Content-Type: application/json; ver=2.0" -d "{\"nationality\":{\"id\":\"string\",\"idType\":\"string\",\"eligible\":true,\"country_id\":\"string\",\"country_name\":\"string\"},\"number\":{\"reservation_id\":\"string\",\"identifier\":\"string\"},\"customer\":{\"email\":\"string\",\"email_verified\":\"string\",\"contact_number_verified\":\"string\",\"contact_number\":\"string\",\"contact_number_encrypted\":\"string\",\"name\":\"string\",\"visitorOTPType\":\"string\",\"countryCode\":\"string\"},\"delivery\":{\"delivery_lat\":\"string\",\"delivery_lng\":\"string\",\"delivery_notes\":\"string\",\"delivery_country\":\"string\",\"delivery_address\":\"string\",\"delivery_city\":\"string\",\"delivery_district\":\"string\",\"delivery_postalcode\":\"string\"},\"plan_id\":\"string\",\"sim_type\":0,\"numberOrderType\":0,\"mnpOperator\":\"string\",\"mnpNumber\":\"string\"}"
                    // const data_for_checkout = {
                    //     "nationality": {
                    //         "id": objs.identity.tcc_Id,
                    //         "idType": objs.identity.tcc_Id,
                    //         "eligible": true,
                    //         "country_id": objs.identity.tcc_Id,
                    //         "country_name": objs.identity.nameEN
                    //     },
                    //     "number": {
                    //         "reservation_id": "",
                    //         "identifier": ""
                    //     },
                    //     "customer": {
                    //         "email": objs.email,
                    //     },
                    //     "delivery": {
                    //         "delivery_lat": "",
                    //         "delivery_lng": "",
                    //         "delivery_notes": "",
                    //         "delivery_country": "",
                    //         "delivery_address": "",
                    //     },
                    //     "plan_id": objs.plan_id,
                    //     "sim_type": 0,
                    //     "numberOrderType": 0,
                    //     "mnpOperator": "",
                    //     "mnpNumber": ""
                    // };
                    // // otpConfirm send to confirm_otp
                    // const res = await checkout(data_for_checkout, providerId)
                    // console.log(res);
                    // if(res.returnData.isSuccess){
                    //     navigate("/local-sim/success");
                    // }
                 }
        } catch (err: any) {
            setError(err.response.data.Message);
        } finally {
            setLoading(false);
        }
    };




    useEffect(() => {
        const interval = setInterval(() => {
            setCounter(prev => (prev === 0 ? 60 : prev - 1));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="card_form">
            <div className="form-group">
                <p>{t("store.otp.info")} {user.phoneNumber}</p>
                <div className="my-3 otp_inputs">
                    {otp.map((digit, index) => (
                        <input
                            autoFocus={index === 0}
                            key={index}
                            type="tel"
                            className="form-control"
                            maxLength={1}
                            value={digit}
                            ref={el => inputRefs.current[index] = el!}
                            onKeyDown={e => handleKeyDown(index, e)}
                            onChange={e => handleOtpChange(index, e.target.value)}
                        />
                    ))}
                </div>
            </div>
            <p className="text-danger">{error}</p>
            <Button inner={t("store.otp.submit")}
                color="primary" isfullWidth onClick={() => verify(otp.join(""))}
                isLoading={loading} />
        </div>
    );
}
export default Check;
