import React, { useEffect, useState } from 'react';
import { ObjectArray } from '../Page';
import { ButtonSquare } from '../../../components/design/buttons/Button';
import { BiCheck, BiLeftArrow, BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import Card from '../../../components/design/cards/card';
import { getProviders } from '../../../services/providers.service';
import { getNumbers } from '../../../services/sim.service';

const NumberCostumOrListOrTransfer: React.FC<ObjectArray> = ({ onOptionSelect, t , i18n, objs}) => {
    const [phoneError, setPhoneError] = useState<string>();
    const [number, setNumber] = useState<string>();
    const [providerId, setProviderId] = useState<number>();
    const [providers,  setProviders] = useState<any[]>([]);
    const [type, setType] = useState<string>("new");
    const fetchProviders = async () => {
        const response = await getProviders(i18n.language);
        // make the id 9 in place of 16 and 16 in place of 9
        console.log(response.returnData);
        const temp = response.returnData[7];
        console.log(temp);
        response.returnData[7] = response.returnData[2];
        response.returnData[2] = temp;
        console.log(response.returnData);
        setProviders(response.returnData.slice(0, 10));
    }
    React.useEffect(() => {
        fetchProviders();
    }, [i18n.language])
    const onClick = () =>
    {
        if (!number) {
            setPhoneError(t("local_sim.number_change_provider.phone_required"));
            return;
        }

        let obj;
        
        if (type === "tansfer") {
            if (!providerId) {
                setPhoneError(t("local_sim.number_change_provider.provider_required"));
                return;
            }
            obj = {
                "phone": number,
                "providerId": providerId
            }
        } else {
            obj = {
                "phone": number
            }
        }

        onOptionSelect(obj);
    }

    useEffect(() => {
        objs && setType(objs.number_type);
        if (type === "tansfer") {
            fetchProviders();
        }
    }, [objs, type])

    return (
        <div>
            {type === "new" ?
                <div>
                    {/* <NumberCostum onSelect={onClick} t={t} /> */}
                    {/* <h1>{t("local_sim.number.new.title")}</h1> */}
                    <ListNumbers onSelect={(number: string) => 
                    {   
                        setNumber(number);
                        onClick();
                    } } t={t} i18n={i18n} objs={objs}   />
                </div>
            :
                    <>
                        <div className="form-group">
                            <label htmlFor="">{t("local_sim.number_change_provider.phone_number")}</label>
                            <input className={"form-control" + (phoneError ? " is-invalid" : "")}
                                />
                            {
                                phoneError && <p className="text-danger">{phoneError}</p>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="">{t("local_sim.number_change_provider.provider")}</label>
                            <select className="form-control" value={providerId} onChange={(e) => setProviderId(Number(e.target.value))}>
                                <option value="">{t("local_sim.number_change_provider.select_provider")}</option>
                                {providers.map((provider) => (
                                    <option key={provider.id} value={provider.id}>
                                        {provider.name}
                                    </option>
                                ))}
                            </select>
                            {
                                providerId && <p className="text-danger">{providerId}</p>
                            }
                        </div>
                        <div className=''>
                            <ButtonSquare inner={t("local_sim.number_change_provider.button")} onClick={onClick} />
                        </div>
                    </>
            }
        </div>
    );
};

const ListNumbers = ({onSelect, t, i18n, objs}: any) => {
    
    const onClick = (number: string) => () => {
        onSelect(number);
    }

    const [numbers, setNumbers] = useState<any[]>([]);
    useEffect(() => {
        getNumbers(null, objs.plan_id, objs.providerId).then((res: any) => {
            setNumbers(res.returnData);
        }).catch((e: any) => {
            setNumbers([
           
            ]);
            console.log(e);
        });

    }, [objs]);

    const [currentNumber, setCurrentNumber] = useState<any>(numbers[0]);

    return (
        <div>
            <div className="mt-4 d-flex flex-column gap-3" >
                {numbers.map((numberObj, index) => (
                    <div className='input_bg p-4 '
                        key={index} onClick={onClick(numberObj.number)} style={{cursor: "pointer"}}>
                        <div className="row">
                            <div className='col m-0 bold'>{numberObj.number}</div>
                            <div className='col m-0 d-flex justify-content-end align-items-center'>
                                {i18n.language === "ar" ? <BiLeftArrowAlt color="rgb(62, 182, 174)" /> : <BiRightArrowAlt color="rgb(62, 182, 174)" />}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}







export default NumberCostumOrListOrTransfer;
 