import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/esm/Offcanvas';
import './OrderCard.css'
import { toast } from "react-toastify";
import Button from '../design/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Drawer from 'react-modern-drawer'
import i18n from '../../../config/i18n';
import OrderItem from './components/OrderItem';
import { getOrders } from '../../services/orders.service';
import { click } from '@testing-library/user-event/dist/click';

class plan {
    id: number;
    title: string;
    price: number;
    currency: string;
    image: string;
    status: string = 'new';
    network: string;
    validity: number;
    constructor(id: number, title: string, price: number, currency: string, image: string, network: string, validity: number,status: string = 'new') {
        this.id = id;
        this.title = title;
        this.price = price;
        this.currency = currency;
        this.image = image;
        this.status = status;
        this.network = network;
        this.validity = validity;
        

    }
}

export const CompareOrderContext = React.createContext(null as any);

const CompareOrderProvider = ({ children }: any) => {
    const {t} = useTranslation();
    const [plans, setPlans] = useState<plan[]>([]);
    const navigate = useNavigate();
    //eanch time the plan changes, add it to the list
    const addToOrder = ( plan:plan,elementId: string,) => {
        if(isInOrder(plan))
        {
            toast.warn(t('compare_order.already_added'), {
                position: toast.POSITION.TOP_CENTER
                });
            return;
        }
        if(elementId != '') {
            //animate the element
            let element = document.getElementById(elementId);
            if (!element) return;
            let clonedElement = element.cloneNode(true);
            //put it over the original element
            if(clonedElement instanceof HTMLElement) {
              clonedElement.style.top = `${element.offsetTop}px`;
              clonedElement.style.left = `${element.offsetLeft}px`;
            }
            //add the animation class
            (clonedElement as HTMLElement).classList.add("dragged");
            let order = document.querySelector('.order');
            let objectRect = element.getBoundingClientRect();
            
            let orderRect = order!.getBoundingClientRect();
            let eleWidth = element.offsetWidth;
            let eleHeight = element.offsetHeight;
            let xDistance = orderRect.left - objectRect.left;
            let yDistance = orderRect.top - objectRect.top;

            // create keyframe animation
            let keyFrames =
                `@keyframes moveToOrder {
                    from {
                    transform: translate(0, 0)
                    opacity: .6;
                    width: ${eleWidth}px;
                    height: ${eleHeight}px;
                    }
                    to {
                    transform: translate(${xDistance}px, ${yDistance}px) scale(0);

                    opacity: 0;
                    width: ${eleWidth}px;
                    }
                }`
            let style = document.createElement('style');
            style.innerHTML = keyFrames;
            document.head.appendChild(style);
            //add the element to the body
            document.body.appendChild(clonedElement);
            
            setPlans([...plans, plan]);
            
        }
        else
        {
            
            setPlans([...plans, plan]);
        }
    }

    //remove the plan from the list
    const removeFromOrder = (plan: any) => {
        setPlans(plans.filter((p: any) => p.id !== plan.id));
    }

    //check if the plan is already in the list
    const isInOrder = (plan: any) => {
        return plans.some((p: any) => p.id === plan.id);
    }

    //render the list of plans
    const renderPlans = (
        close
    ) => {
        return plans.map((plan: any) => {
            const status = plan.status;

            let button;
            switch(status) {
                case 'processing':
                    button = <ProgressBar step={1} />;
                    break;
                case 'delivered':
                    button = t("compare_order.install_esim");
                    break;
                case 'Installed':
                    button = t("compare_order.activate_esim");
                    break;
                case 'activated':
                    button = t("compare_order.activate_esim");
                break;   
            }
            
            return (
                // <div className="plan-in-order me-5" key={plan.id}>
                //     <div className="d-flex justify-content-between align-items-center">
                //         <div className="plan-image">
                //             <img src={plan.image} alt={plan.title} width="50" height="50" />
                //         </div>
                //         <div className="plan-remove" onClick={() => removeFromOrder(plan)}>
                //             <i className="fa fa-times"></i>
                //         </div>
                //     </div>
                //     <div className="plan-title">
                //         {plan.title}
                //     </div>
                //     <div className="plan-type">
                //         {plan.type}
                //     </div>   
                // </div>
                <OrderItem plan={plan} onClick={
                    () => 
                        {
                            navigate('/order/?id=' + plan.id);
                            close();
                        }
                } button={button} />
            )
        })
    }
            
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const GetOrders = async () => {
        const ret = await getOrders(i18n.language);
        if(ret!.status === 200) {
            const data = ret!.data.data;
            const ordrs = [] as any;
            for(let i = 0; i < data.length; i++) {
                if(data[i].orderDeitalsDtos[0])
                {
                    let p = new plan(data[i].id, data[i].orderDeitalsDtos[0].planName, data[i].orderTotalAmount, 'SAR', '/media/svgs/sim.svg',data[i].orderDeitalsDtos[0].network,data[i].orderDeitalsDtos[0].validityTimeInDays, 'Installed');
                    ordrs.push(p);
                }
                }
            setPlans(ordrs);
        }
    }

    useEffect(() => {
        GetOrders();
        setPlans([
            // new plan(1, 'plan 1', 100, 'SAR', '/media/svgs/sim.svg', 'processing'),
            // new plan(2, 'plan 2', 200, 'SAR', '/media/svgs/sim.svg', 'delivered'),
            // new plan(3, 'plan 2', 200, 'SAR', '/media/svgs/sim.svg', 'Installed'),
        ]);
    }, [window.location.pathname])
    



        

    const pathInfo = window.location.pathname.split('/');
    

    const nouser= localStorage.getItem('tygo_user') ? false : true;


  return (
    <CompareOrderContext.Provider value={{plans, addToOrder, removeFromOrder, isInOrder, renderPlans}}>
        <div className={nouser ? 'nouser' : ''}>
        {children}
        {(
            !pathInfo.includes('stepper')
        ) && (
            <>
        <div className=
        {"order closed" + ((plans.length > 0) || (pathInfo.includes('compare') || pathInfo.includes('plans')) ? ' d-block' : ' d-none')}
        >
            <button className="position-absolute close-button" onClick={handleShow}>
                <i className="fa fa-times"></i></button>
            <div className="inner-button" onClick={() => {
                const order = document.querySelector('.order');
                if(order instanceof HTMLElement) {
                    handleShow();
                }
            }}>

                <img src="/media/svgs/sim.svg" alt="compare" width={20} />
                <strong>{t('compare_order.orders_list')}</strong>
                <span className="order-count">{plans.length}</span>
            </div>

        
            <div className="order-body">
                {plans.length > 0 ? renderPlans(
                    () => handleClose
                ) : <div className="empty-order">
                   {t('compare_order.orders_list')}
                    </div>}
            </div>
            {
                plans.length > 1 ? (
                    <div className="order-footer">
                        <strong className="order-total">{t('compare_order.total')}:
                         {plans.length}</strong>
                        <div className="order-buttons d-flex">
                        

                            <Button color="primary" onClick={() => {
                                const order = document.querySelector('.order');
                                if(order instanceof HTMLElement)
                                    order.classList.add('closed');
                            
                                navigate('/compare');
                            }} inner={t("compare_order.compare")} />

                        </div>
                    </div>
                ) : plans.length === 1 ? (
                    <div className="order-footer">
                        <span className='alert alert-danger m-0'>{t('compare_order.add_more')}</span>
                     
                    </div>
                ) : null

            }
        
        </div>
        <Drawer 
        open={show}
         onClose={handleClose} 
         direction={i18n.language !== 'ar' ? 'right' : 'left'}
        //  size={"100%"}
         zIndex={1001}
         style={{width: '100%', maxWidth: '450px'}}
         className='order-drawer h-100'>
            <div className="order-header">
                <span>{t('compare_order.orders_list')}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={handleClose}>
                    <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="#2F1952"/>
                </svg>
                
            </div>
            <div className="order-body h-100" style={{overflowY: 'auto'}}>
                {plans.length > 0 ? renderPlans(
                    () => handleClose
                ) : <div className="empty-order">
                   {t('compare_order.no_orders')}
                    </div>}
                {/* <Button color="primary" onClick={() => {
                    handleClose();
                    navigate('/store');
                }} inner={t("Go to Store")} isrounded={true} /> */}
            </div>
            {
                plans.length > 1 ? (
                    <div className="order-footer">
                        <strong className="order-total">{t('compare_order.total')}:
                         {plans.length}</strong>
                        <div className="order-buttons d-flex">
                            <Button color="primary" onClick={() => {
                                const order = document.querySelector('.order');
                                if(order instanceof HTMLElement)
                                    order.classList.add('closed');
                            
                                navigate('/compare');
                            }} inner={t("compare_order.compare")} />

                        </div>
                    </div>
                ) : plans.length === 1 ? (
                    <div className="order-footer">
                        <span className='alert alert-danger m-0'>{t('compare_order.add_more')}</span>
                    </div>
                ) : null

            }
        </Drawer>

        </>
        )}
        </div>
    </CompareOrderContext.Provider>
  );
}

export default CompareOrderProvider;

const ProgressBar = ({step}: any) => {
    const {t, i18n} = useTranslation();
    


    return (
        <div className="progress-bar">
            <div className="progress-bar-inner">
                <div className="progress-bar-inner-fill" style={{width: `${step ===0 ? 0 : step === 1 ? 50 : 100}%`}}></div>
            </div>
            <div className="progress-bar-steps">
                <div className={"progress-bar-step" + (step >= 0 ? ' active' : '')}>
                    <span className="progress-bar-step-title">{t('compare_order.step1')}</span>
                </div>
                <div className={"progress-bar-step" + (step >= 1 ? ' active' : '')}>
                    <span className="progress-bar-step-title">{t('compare_order.step1')}</span>
                </div>
                <div className={"progress-bar-step" + (step === 2 ? ' active' : '')}>
                    <span className="progress-bar-step-title">{t('compare_order.step1')}</span>
                </div>
            </div>
            
        </div>
    )
}
